<template>
  <div class="build-layout error-build">
    <div class="container px-4 px-sm-3 mx-auto h-100">
      <div class="row build-row">
        <div class="col-12 col-md-5">
          <!-- ERROR CONTAINER -->
          <div class="error-container">
            <!-- ERROR IMAGE BLOCK -->
            <div class="error-logo-block w-100 mgb-15">
              <img v-lazy="staticAsset('Tree.svg', 'error')" alt="" />
            </div>

            <!-- ERROR ENTRY -->
            <router-view />

            <!-- HOME BUTTON -->
            <div
              class="button-row d-flex justify-content-center align-items-start flex-nowrap"
            >
              <router-link to="/" class="btn btn-md btn-primary"
                >Back to Home</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "errorLayout",
};
</script>

<style lang="scss">
// AUTH BUILD LAYOUT
.build-layout {
  .build-row {
    @include flex-row-center-nowrap;

    .error-logo-block {
      @include flex-row-center-nowrap;
    }
  }
}

.error-build {
  @include stretch-area;
  @include flex-column-center;

  .build-row {
    height: 100vh;

    .error-logo-block {
      img {
        @include rectangle-shape(250, 220);
      }
    }
  }

  .error-title {
    @include font-height(52, 62);
  }

  .info-text {
    @include font-height(17, 28);
  }

  .info-sub-text {
    @include font-height(16, 26);
  }
}
</style>
